import { GET_PERMISSION_BY_EMAIL } from "../constants";
import { getLocalStorage } from "../utils";

export interface GetPermissionByEmailRequest {
  clientEmail: string;
}

export const getPermissionByEmail = async (getPermissionRequest: GetPermissionByEmailRequest): Promise<any> => {
  try {
    const getPermissionResponse = await fetch(GET_PERMISSION_BY_EMAIL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
      body: JSON.stringify(getPermissionRequest),
    });
    const getPermissionResponseContent = await getPermissionResponse.json();

    if (getPermissionResponseContent.data.message === "Success") {
      return getPermissionResponseContent.data.result.permissionData;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
