import { ADD_NEW_CLIENTS } from "../constants";
import { getLocalStorage } from "../utils";

export interface AddNewClientRequest {
  clientEmail: any;
  clientName: any;
  clientId: any;
  clientAddress: string;
}

export const addNewClient = async (clientRequest: AddNewClientRequest): Promise<any> => {
  try {
    const clientResponse = await fetch(ADD_NEW_CLIENTS, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
      body: JSON.stringify(clientRequest),
    });
    const clientContent = await clientResponse.json();

    return clientContent.data.message === "Success";
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
