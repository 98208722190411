import React, { useContext } from "react";

import { SampleCard } from "../../components";
import { ISample, StyleContext } from "../../context";
import { Divider } from "@mui/material";

interface IOngoingListing {
  handleShowSampleDetail: (value: string) => void;
  localOngoingSamples: ISample[];
  localCompletedSamples: ISample[];
}

export const OngoingListing = ({ handleShowSampleDetail, localOngoingSamples, localCompletedSamples }: IOngoingListing) => {
  // const isFocused = useIsFocused();
  // useEffect(() => {
  //   if (isFocused !== null && isFocused) {
  //     scrollViewRef.current?.scrollTo(0, 0, true);
  //   }
  // }, [isFocused]);

  const { styleState } = useContext(StyleContext);

  const sampleCardProps = {
    handleShowSampleDetail: handleShowSampleDetail,
  };

  // console.log("ongoing allSamplesForAdmin", allSamplesForAdmin);

  return (
    <div style={{ overflowY: "scroll", height: window.innerHeight - styleState.headerHeight, backgroundColor: "white" }}>
      {localOngoingSamples.map((eachSample, index) => {
        return (
          <div style={{ paddingTop: 4, paddingBottom: 8 }} key={`${index}${eachSample.sampleId}`}>
            <SampleCard isCompleted={eachSample.isCompleted} {...sampleCardProps} cardItem={eachSample} />
            {<Divider style={{ marginRight: 20, marginLeft: 20 }} />}
          </div>
        );
      })}
    </div>
  );
};
