import { Backdrop, Button, ButtonGroup, CircularProgress } from "@mui/material";
import { Box, Heading, HStack } from "native-base";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CustomAlert, Spacer } from "../../components";
import { ISample } from "../../context";
import { h16, h24, h8, w12, w24, w8 } from "../../styles";
import { AddClientDetails } from "./AddClientDetails";
import { NetworkActions } from "../../network-actions";

export interface INewSample {
  sampleData: Omit<ISample, "reference">;
}

export type AddSampleProcess = "client" | "parameter" | "preview";

const initialNewSamples: INewSample = {
  sampleData: {
    clientEmail: "",
    batchNumber: "",
    clientId: "",
    clientName: "",
    sampleLocation: "",
    touchPoint: "",
    clientRequestor: "",
    sampleId: "",
    sampleType: "",
    sampleName: "",
    samplingDate: "",
    receivedDate: "",
    reportRef: [],
    isCompleted: false,
    isPaid: false,
  },
};

const reducer = (state: INewSample, action: { type: string; item: any }) => {
  switch (action.type) {
    case "add_item":
      return {
        ...state,
        sampleData: { ...state.sampleData, ...action.item },
      };
    case "reset":
      return { ...initialNewSamples };
    default:
      return state;
  }
};

export const AddOneSample = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialNewSamples);
  const [clientItems, setClientItems] = useState<IClientItems[] | []>([]);
  const [isNewCustomer, setIsNewCustomer] = useState<boolean>(false);
  const [formErrorMessage, setFormErrorMessage] = useState<string>("");
  const [fetching, setFetching] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // fetch functions
  const handleFetchAllClients = async () => {
    try {
      const clientResponse: IClientItems[] = await NetworkActions.getAllClients();

      if (clientResponse) {
        const filteredClientItems: IClientItems[] = [];
        clientResponse
          .sort((a, b) => (a.clientName > b.clientName ? 1 : -1))
          .filter((eachClient) => eachClient.clientId !== undefined && eachClient.clientId !== "" && eachClient.clientId !== "-")
          .filter((eachClient) => eachClient.clientEmail !== undefined && eachClient.clientEmail !== "" && eachClient.clientEmail !== "-")
          .forEach((eachClient) => {
            const isUnique = filteredClientItems.some((filteredClient) => filteredClient.clientId === eachClient.clientId) === false;
            if (isUnique) {
              filteredClientItems.push(eachClient);
            }
          });
        setClientItems(filteredClientItems);
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET ALL CLIENT DATA");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleFetchParamsData = async () => {
    await handleFetchAllClients();
  };
  const handleAddNewSample = async () => {
    try {
      const addNewSampleResponse = await NetworkActions.addNewOneSample(state.sampleData);

      if (addNewSampleResponse) {
        navigate("/dashboard");
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO ADD NEW SAMPLE");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleSetNewClient = async () => {
    const clientRequest = {
      clientEmail: state.sampleData.clientEmail,
      clientName: state.sampleData.clientName,
      clientId: state.sampleData.clientId,
      clientAddress: "",
    };
    try {
      const clientResponse = await NetworkActions.addNewClient(clientRequest);

      if (!clientResponse) {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO ADD NEW CLIENT");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleNext = async () => {
    if (state.sampleData.clientEmail !== "" && state.sampleData.sampleId !== "" && state.sampleData.clientId !== "") {
      setFetching(true);
      if (isNewCustomer) {
        await handleSetNewClient();
      }
      // run API to add new sample
      await handleAddNewSample();
      setFetching(false);
    } else if (isNewCustomer === true && state.sampleData.clientName === "") {
      setFormErrorMessage("Client name is required");
    } else if (state.sampleData.clientEmail === "" && isNewCustomer === true) {
      setFormErrorMessage("Client email is required");
    } else if (state.sampleData.clientEmail === "") {
      setFormErrorMessage("Select a company");
    } else if (isNewCustomer === true && state.sampleData.clientId === "") {
      setFormErrorMessage("Client id is required");
    } else {
      setFormErrorMessage("Sample Id is required");
    }
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  const handleBack = () => {
    handleCancel();
  };

  const pageProps = {
    state,
    dispatch,
    setIsNewCustomer,
    isNewCustomer,
    formErrorMessage,
  };

  useEffect(() => {
    handleFetchParamsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <Box paddingX={w24} paddingY={h16}>
      <Box>
        <Heading size="xl" ml="-1">
          Add Sample Detail
        </Heading>
        <Spacer space={h24} />
      </Box>
      <AddClientDetails {...pageProps} clientItems={clientItems} />
      <HStack space={w12} marginTop={h24}>
        <ButtonGroup variant="outlined" aria-label="outlined button group" style={{ flex: 1 }}>
          <Button aria-label="button" color={"warning"} style={{ cursor: "pointer", flex: 1 }} onClick={handleBack}>
            Cancel
          </Button>
          <Button aria-label="button" style={{ cursor: "pointer", flex: 1 }} onClick={handleNext}>
            Confirm
          </Button>
        </ButtonGroup>
      </HStack>

      {/* alert show error message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {error ? <CustomAlert alertMessage={errorMessage} /> : null}
      </Box>

      {/* loading network modal */}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 5 }} open={fetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
