import { ADD_NEW_ONE_SAMPLE } from "../constants";
import { ISample } from "../context";
import { getLocalStorage } from "../utils";

export interface AddNewOneSampleRequest {
  sampleItem: Omit<ISample, "reference">;
}

export const addNewOneSample = async (sampleItem: AddNewOneSampleRequest): Promise<any> => {
  try {
    const addNewSampleResponse = await fetch(ADD_NEW_ONE_SAMPLE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
      body: JSON.stringify(sampleItem),
    });
    const addNewSampleContent = await addNewSampleResponse.json();

    if (addNewSampleContent.data.message === "Success") {
      return new Promise((resolve, reject) => {
        resolve("Success");
      });
    }

    return new Promise((resolve, reject) => {
      resolve(undefined);
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
