export const colorWhite = {
  _1: "white",
};

export const colorBlue = {
  _1: "#002043",
  _2: "#F2F4F6",
  _3: "#C6CBd4",
};

export const colorBlack = {
  _1: "#000000",
  _2: "#171717",
  _3: "#rgba(0,0,0,0.4)",
};

export const themeColor = {
  primary: "#31304D",
  secondary: "#605E7E",
};
