import { UPDATE_PERMISSION_BY_REF } from "../constants";
import { getLocalStorage } from "../utils";

export interface UpdatePermissionByRefRequest {
  docReference: string | undefined;
  clientId?: string | undefined;
  clientEmail?: string | undefined;
  isClient?: boolean | undefined;
  isAllowed?: boolean | undefined;
  adminPermissionLevel?: string | undefined;
}

export const updatePermissionByRef = async (updatePermissionRequest: UpdatePermissionByRefRequest): Promise<any> => {
  try {
    const permissionResponse = await fetch(UPDATE_PERMISSION_BY_REF, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
      body: JSON.stringify(updatePermissionRequest),
    });
    const permissionContent = await permissionResponse.json();

    return permissionContent.data.message === "Success";
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }

  return false;
};
