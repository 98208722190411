import { addNewClient } from "./addNewClient";
import { addNewOneSample } from "./addNewOneSample";
import { addNewPermission } from "./addNewPermission";
import { authLogin } from "./authLogin";
import { authSignUp } from "./authSignUp";
import { deleteClientByRef } from "./deleteClientByRef";
import { deletePermissionByRef } from "./deletePermissionByRef";
import { deleteSampleByRef } from "./deleteSampleByRef";
import { getAllClients } from "./getAllClients";
import { getAllPermission } from "./getAllPermission";
import { getAllSampleForAdmin } from "./getAllSampleForAdmin";
import { getAllSamplesOfAnIndex } from "./getAllSamplesOfAnIndex";
import { getPermissionByEmail } from "./getPermissionByEmail";
import { getSampleByRef } from "./getSampleByRef";
import { updateClientByRef } from "./updateClientByRef";
import { updatePermissionByRef } from "./updatePermissionByRef";
import { updateSampleReportByRef } from "./updateSampleReportByRef";
import { updateSampleStatusByRef } from "./updateSampleStatusByRef";
import { verifyAdminPermission } from "./verifyAdminPermission";

export const NetworkActions = {
  getAllSampleForAdmin: getAllSampleForAdmin,
  getAllClients: getAllClients,
  addNewClient: addNewClient,
  addNewOneSample: addNewOneSample,
  authLogin: authLogin,
  authSignUp: authSignUp,
  deleteClientByRef: deleteClientByRef,
  deletePermissionByRef: deletePermissionByRef,
  getAllSamplesOfAnIndex: getAllSamplesOfAnIndex,
  getPermissionByEmail: getPermissionByEmail,
  updateClientByRef: updateClientByRef,
  verifyAdminPermission: verifyAdminPermission,
  getAllPermission: getAllPermission,
  updatePermissionByRef: updatePermissionByRef,
  addNewPermission: addNewPermission,
  updateSampleReportByRef: updateSampleReportByRef,
  updateSampleStatusByRef: updateSampleStatusByRef,
  getSampleByRef: getSampleByRef,
  deleteSampleByRef: deleteSampleByRef,
};
