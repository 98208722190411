import { Box, HStack, Text } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { InterfaceTextProps } from "native-base/lib/typescript/components/primitives/Text/types";
import React from "react";

export interface ICustomItemBoxStyle {
  boxStyle?: InterfaceBoxProps;
  titleStyle?: InterfaceTextProps;
}

interface ILabelledItemBoxed {
  label: string;
  item: string | undefined;
  titleBoxStyle?: ICustomItemBoxStyle;
  labelBoxStyle?: ICustomItemBoxStyle;
}

export const LabelledItemBoxed = ({ label, item, titleBoxStyle, labelBoxStyle }: ILabelledItemBoxed) => {
  const customTitleBoxStyle = titleBoxStyle !== undefined ? titleBoxStyle.boxStyle : {};
  const customTitleTextStyle = titleBoxStyle !== undefined ? titleBoxStyle.titleStyle : {};

  const customLabelBoxStyle = labelBoxStyle !== undefined ? labelBoxStyle.boxStyle : {};
  const customLabelTextStyle = labelBoxStyle !== undefined ? labelBoxStyle.titleStyle : {};

  return (
    <HStack flex={1} borderTopWidth={1} py={0.5}>
      <Box borderRightWidth={1} alignItems={"center"} justifyContent={"center"} {...customTitleBoxStyle}>
        <Text paddingLeft={2} fontWeight={"semibold"} width={180} alignItems={"center"} {...customTitleTextStyle}>
          {label}
        </Text>
      </Box>

      <Box alignItems={"center"} justifyContent={"center"} {...customLabelBoxStyle}>
        <Text flexWrap={"wrap"} paddingLeft={2} {...customLabelTextStyle}>
          {item !== undefined ? item : "-"}
        </Text>
      </Box>
    </HStack>
  );
};
