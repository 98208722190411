import { UPDATE_CLIENT_BY_REF } from "../constants";
import { getLocalStorage } from "../utils";

export interface UpdateClientByRefRequest {
  docReference: string | undefined;
  clientName?: string | undefined;
  clientId?: string | undefined;
  clientEmail?: string | undefined;
  clientAddress?: string | undefined;
}

export const updateClientByRef = async (updateClientRequest: UpdateClientByRefRequest): Promise<any> => {
  try {
    const updateClientResponse = await fetch(UPDATE_CLIENT_BY_REF, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
      body: JSON.stringify(updateClientRequest),
    });
    const updateClientContent = await updateClientResponse.json();

    return updateClientContent.data.message === "Success";
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }

  return false;
};
