import { GET_ALL_SAMPLE_FOR_ADMIN } from "../constants";
import { getLocalStorage } from "../utils";

export interface PaginationRequest {
  page: number;
  tab?: string;
  searchText?: string;
  byCategory?: string;
}

export const getAllSampleForAdmin = async (paginationRequest: PaginationRequest): Promise<any> => {
  try {
    // normal fetch working
    const samplesResponse = await fetch(GET_ALL_SAMPLE_FOR_ADMIN, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
      body: JSON.stringify(paginationRequest),
    });
    const samplesContent = await samplesResponse.json();

    // console.log(">>>> paginationRequest", JSON.stringify(paginationRequest));
    // console.log(">>>> samplesContent", JSON.stringify(samplesContent));

    if (samplesContent.data.message === "Success") {
      return samplesContent.data.result.data;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
