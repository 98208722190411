import { Box, CheckIcon, FormControl, HStack, Input, Select, Text, View } from "native-base";
import React, { useState } from "react";

import { Spacer } from "../../components";
import { h24, w16, w4, w8 } from "../../styles";
import { INewSample } from "./AddOneSample";

interface IAddClientDetails {
  state: INewSample;
  dispatch: React.Dispatch<{
    type: string;
    item: any;
  }>;
  clientItems: IClientItems[];
  setIsNewCustomer: (value: boolean) => void;
  isNewCustomer: boolean;
  formErrorMessage: string;
}

export const AddClientDetails = ({
  state,
  dispatch,
  clientItems,
  setIsNewCustomer,
  isNewCustomer,
  formErrorMessage,
}: IAddClientDetails) => {
  // update reducer functions
  const handleUpdateClientName = (value: string) => {
    dispatch({ type: "add_item", item: { clientName: value } });
  };
  const handleUpdateClientEmail = (value: string) => {
    dispatch({ type: "add_item", item: { clientEmail: value } });
  };
  const handleUpdateClientId = (value: string) => {
    dispatch({ type: "add_item", item: { clientId: value } });
  };
  const handleUpdateBatchNumber = (value: string) => {
    dispatch({ type: "add_item", item: { batchNumber: value } });
  };
  const handleUpdateSampleLocation = (value: string) => {
    dispatch({ type: "add_item", item: { sampleLocation: value } });
  };
  const handleUpdateTouchPoint = (value: string) => {
    dispatch({ type: "add_item", item: { touchPoint: value } });
  };
  const handleUpdateClientRequestor = (value: string) => {
    dispatch({ type: "add_item", item: { clientRequestor: value } });
  };
  const handleUpdateSampleId = (value: string) => {
    dispatch({ type: "add_item", item: { sampleId: value } });
  };
  const handleUpdateSampleType = (value: string) => {
    dispatch({ type: "add_item", item: { sampleType: value } });
  };
  const handleUpdateSampleName = (value: string) => {
    dispatch({ type: "add_item", item: { sampleName: value } });
  };
  const handleUpdateSamplingDate = (value: string) => {
    dispatch({ type: "add_item", item: { samplingDate: value } });
  };
  const handleUpdateReceivedDate = (value: string) => {
    dispatch({ type: "add_item", item: { receivedDate: value } });
  };

  const filteredClientItems: IClientItems[] = [];
  clientItems
    .sort((a, b) => (a.clientName > b.clientName ? 1 : -1))
    .forEach((eachClient) => {
      const isUnique = filteredClientItems.some((filteredClient) => filteredClient.clientId === eachClient.clientId) === false;
      if (isUnique) {
        filteredClientItems.push(eachClient);
      }
    });

  return (
    <Box>
      {/* select company */}
      <FormControl>
        <HStack space={w8}>
          <View flex={1}>
            <FormControl.Label>Choose Company *</FormControl.Label>
            <Select
              minWidth="200"
              placeholder="Choose Company"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={5} />,
              }}
              selectedValue={!isNewCustomer ? state.sampleData.clientEmail : "others"}
              onValueChange={(value) => {
                if (value === "others") {
                  // free text of client name & client email
                  setIsNewCustomer(true);
                  handleUpdateClientName("");
                  handleUpdateClientEmail("");
                  handleUpdateClientId("");
                } else {
                  setIsNewCustomer(false);
                  const selectedClient = filteredClientItems.filter((eachClient) => eachClient.clientEmail === value);
                  if (selectedClient.length > 0) {
                    handleUpdateClientName(selectedClient[0].clientName);
                    handleUpdateClientEmail(selectedClient[0].clientEmail);
                    handleUpdateClientId(selectedClient[0].clientId);
                  }
                }
              }}
              mt="1">
              {filteredClientItems.map((eachCompany) => {
                return <Select.Item label={eachCompany.clientName} value={eachCompany.clientEmail} />;
              })}
              <Select.Item label={"Others"} value={"others"} />
            </Select>
            {formErrorMessage !== "" && formErrorMessage.toLocaleLowerCase().includes("company") ? (
              <View>
                <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                  **{formErrorMessage}
                </Text>
              </View>
            ) : null}
          </View>

          {/* <View flex={1}>
            <FormControl.Label>Batch Number *</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateBatchNumber}
              placeholder={"batch number"}
              value={state.sampleData.batchNumber}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                // width: 300,
              }}
            />
            {formErrorMessage !== "" && formErrorMessage.toLocaleLowerCase().includes("batch") ? (
              <View>
                <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                  **{formErrorMessage}
                </Text>
              </View>
            ) : null}
          </View> */}
        </HStack>

        <Spacer space={h24} />
      </FormControl>

      <FormControl>
        {/* select client name, requestor, sample id */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sample ID *</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleId}
              placeholder={"sample id"}
              value={state.sampleData.sampleId}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
            {formErrorMessage !== "" &&
            formErrorMessage.toLocaleLowerCase().includes("sample") &&
            formErrorMessage.toLocaleLowerCase().includes("id") ? (
              <View>
                <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                  **{formErrorMessage}
                </Text>
              </View>
            ) : null}
          </View>
          <View flex={1}>
            <FormControl.Label>Company Name *</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateClientName}
              placeholder={"company name"}
              value={state.sampleData.clientName}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
              isDisabled={!isNewCustomer}
            />
            {formErrorMessage !== "" && formErrorMessage.toLocaleLowerCase().includes("name") ? (
              <View>
                <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                  **{formErrorMessage}
                </Text>
              </View>
            ) : null}
          </View>
          {isNewCustomer ? (
            <View flex={1.5} flexDirection={"row"}>
              <View flex={1} marginRight={w4}>
                <FormControl.Label>Company Email *</FormControl.Label>
                <Input
                  autoCapitalize={"none"}
                  onChangeText={handleUpdateClientEmail}
                  placeholder={"company email"}
                  value={state.sampleData.clientEmail}
                  variant="outline"
                  style={{
                    padding: w16,
                    borderRadius: 4,
                    width: 300,
                  }}
                />
                {formErrorMessage !== "" && formErrorMessage.toLocaleLowerCase().includes("email") ? (
                  <View>
                    <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                      **{formErrorMessage}
                    </Text>
                  </View>
                ) : null}
              </View>

              <View flex={1}>
                <FormControl.Label>Company Id *</FormControl.Label>
                <Input
                  autoCapitalize={"none"}
                  onChangeText={handleUpdateClientId}
                  placeholder={"company id"}
                  value={state.sampleData.clientId}
                  variant="outline"
                  style={{
                    padding: w16,
                    borderRadius: 4,
                    width: 300,
                  }}
                />
                {formErrorMessage !== "" &&
                formErrorMessage.toLocaleLowerCase().includes("id") &&
                formErrorMessage.toLocaleLowerCase().includes("client") ? (
                  <View>
                    <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                      *{formErrorMessage}
                    </Text>
                  </View>
                ) : null}
              </View>
            </View>
          ) : null}
          <View flex={isNewCustomer ? 0.5 : 1}>
            <FormControl.Label>Requestor</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateClientRequestor}
              placeholder={"requestor"}
              value={state.sampleData.clientRequestor}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>
        <Spacer space={h24} />

        {/* section sample info */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sample Location</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleLocation}
              placeholder={"sample location"}
              value={state.sampleData.sampleLocation}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
          <View flex={1}>
            <FormControl.Label>Sample Point</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateTouchPoint}
              placeholder={"sample point"}
              value={state.sampleData.touchPoint}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>
        <Spacer space={h24} />

        {/* section sample type, name, isUsed */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sample Type</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleType}
              placeholder={"sample type"}
              value={state.sampleData.sampleType}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>

          <View flex={1}>
            <FormControl.Label>Sample Name</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleName}
              placeholder={"sample name"}
              value={state.sampleData.sampleName}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>
        <Spacer space={h24} />

        {/* section sample date */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sampling Date</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSamplingDate}
              placeholder={"sampling date"}
              value={state.sampleData.samplingDate}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>

          <View flex={1}>
            <FormControl.Label>Received Date</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateReceivedDate}
              placeholder={"received date"}
              value={state.sampleData.receivedDate}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>
      </FormControl>
    </Box>
  );
};
