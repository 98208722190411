import { Box, FormControl, HStack, Input, Radio, Text, View } from "native-base";
import React, { useState } from "react";

import { Spacer } from "../../components";
import { h24, w16, w4 } from "../../styles";
import { INewSample } from "./UpdateOneSample";

interface IUpdateClientDetails {
  state: INewSample;
  dispatch: React.Dispatch<{
    type: string;
    item: any;
  }>;
  formErrorMessage: string;
}

export const UpdateClientDetails = ({ state, dispatch, formErrorMessage }: IUpdateClientDetails) => {
  // client details // disabled
  const handleUpdateClientName = (value: string) => {
    dispatch({ type: "add_item", item: { clientName: value } });
  };
  const handleUpdateClientEmail = (value: string) => {
    dispatch({ type: "add_item", item: { clientEmail: value } });
  };
  const handleUpdateClientId = (value: string) => {
    dispatch({ type: "add_item", item: { clientId: value } });
  };
  const handleUpdateSampleId = (value: string) => {
    dispatch({ type: "add_item", item: { sampleId: value } });
  };
  const handleUpdateBatchNumber = (value: string) => {
    dispatch({ type: "add_item", item: { batchNumber: value } });
  };

  // update sample details // can be updated
  const handleUpdateSampleLocation = (value: string) => {
    dispatch({ type: "add_item", item: { sampleLocation: value } });
  };
  const handleUpdateTouchPoint = (value: string) => {
    dispatch({ type: "add_item", item: { touchPoint: value } });
  };
  const handleUpdateClientRequestor = (value: string) => {
    dispatch({ type: "add_item", item: { clientRequestor: value } });
  };
  const handleUpdateSampleType = (value: string) => {
    dispatch({ type: "add_item", item: { sampleType: value } });
  };
  const handleUpdateSampleName = (value: string) => {
    dispatch({ type: "add_item", item: { sampleName: value } });
  };
  const handleUpdateSamplingDate = (value: string) => {
    dispatch({ type: "add_item", item: { samplingDate: value } });
  };
  const handleUpdateReceivedDate = (value: string) => {
    dispatch({ type: "add_item", item: { receivedDate: value } });
  };
  const handleUpdateIsCompleted = (value: boolean) => {
    dispatch({ type: "add_item", item: { isCompleted: value } });
  };
  const handleUpdateIsPaid = (value: boolean) => {
    dispatch({ type: "add_item", item: { isPaid: value } });
  };

  return (
    <Box>
      <FormControl>
        {/* client details */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Company Name</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleId}
              placeholder={"sample id"}
              value={state.sampleData.clientName}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
              isDisabled={true}
            />
            {formErrorMessage !== "" && formErrorMessage.toLocaleLowerCase().includes("company") ? (
              <View>
                <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                  **{formErrorMessage}
                </Text>
              </View>
            ) : null}
          </View>
          {/* <View flex={1} marginRight={w4}>
            <FormControl.Label>Company Email</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateClientEmail}
              placeholder={"company email"}
              value={state.sampleData.clientEmail}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
              isDisabled={true}
            />
            {formErrorMessage !== "" && formErrorMessage.toLocaleLowerCase().includes("email") ? (
              <View>
                <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                  **{formErrorMessage}
                </Text>
              </View>
            ) : null}
          </View> */}
          <View flex={1}>
            <FormControl.Label>Sample ID</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleId}
              placeholder={"sample id"}
              value={state.sampleData.sampleId}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
              isDisabled={true}
            />
            {formErrorMessage !== "" &&
            formErrorMessage.toLocaleLowerCase().includes("sample") &&
            formErrorMessage.toLocaleLowerCase().includes("id") ? (
              <View>
                <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                  **{formErrorMessage}
                </Text>
              </View>
            ) : null}
          </View>
          {/* <View flex={1}>
            <FormControl.Label>Batch Number</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateBatchNumber}
              placeholder={"batch number"}
              value={state.sampleData.batchNumber}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View> */}

          <View flex={1}>
            <FormControl.Label>Requestor</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateClientRequestor}
              placeholder={"requestor"}
              value={state.sampleData.clientRequestor}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>
        <Spacer space={h24} />

        {/* section sample info */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sample Location</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleLocation}
              placeholder={"sample location"}
              value={state.sampleData.sampleLocation}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
          <View flex={1}>
            <FormControl.Label>Sample Point</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateTouchPoint}
              placeholder={"sample point"}
              value={state.sampleData.touchPoint}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>
        <Spacer space={h24} />

        {/* section sample type, name, isUsed */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sample Type</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleType}
              placeholder={"sample type"}
              value={state.sampleData.sampleType}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>

          <View flex={1}>
            <FormControl.Label>Sample Name</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleName}
              placeholder={"sample name"}
              value={state.sampleData.sampleName}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>
        <Spacer space={h24} />

        {/* section sample date */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sampling Date</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSamplingDate}
              placeholder={"sampling date"}
              value={state.sampleData.samplingDate}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>

          <View flex={1}>
            <FormControl.Label>Received Date</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateReceivedDate}
              placeholder={"received date"}
              value={state.sampleData.receivedDate}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>

        <Spacer space={h24} />

        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label
              _text={{
                fontSize: "sm",
                bold: true,
              }}>
              Completed ?
            </FormControl.Label>

            <Radio.Group
              name="Is Completed ?"
              value={state.sampleData.isCompleted ? "1" : "2"}
              onChange={(value) => {
                handleUpdateIsCompleted(value === "1" ? true : false);
              }}>
              <HStack space={4} alignItems={"center"}>
                <Radio value="1" my="1">
                  Completed
                </Radio>
                <Radio value="2" my="2">
                  Pending
                </Radio>
              </HStack>
            </Radio.Group>
          </View>

          <View flex={1}>
            <FormControl.Label
              _text={{
                fontSize: "sm",
                bold: true,
              }}>
              Paid ?
            </FormControl.Label>

            <Radio.Group
              name="Is Paid ?"
              value={state.sampleData.isPaid ? "1" : "2"}
              onChange={(value) => {
                handleUpdateIsPaid(value === "1" ? true : false);
              }}>
              <HStack space={4} alignItems={"center"}>
                <Radio value="1" my="1">
                  Paid
                </Radio>
                <Radio value="2" my="2">
                  Pending
                </Radio>
              </HStack>
            </Radio.Group>
          </View>
        </HStack>
      </FormControl>
    </Box>
  );
};
