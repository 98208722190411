import { Backdrop, Button, ButtonGroup, CircularProgress } from "@mui/material";
import { Box, Modal, Text } from "native-base";
import React, { useEffect, useState } from "react";

import { ContentContainer, CustomAlert, ListingTitlePermission, Spacer } from "../../components";

import { h12, h4, h48, h8, w8 } from "../../styles";
import { AddNewPermission } from "./AddNewPermission";
import { ListingPermission } from "./ListingPermission";
import { UpdatePermission } from "./UpdatePermission";
import { NetworkActions } from "../../network-actions";

export interface IPermissionItems {
  isClient: boolean;
  isAllowed: boolean;
  clientId: string;
  clientEmail: string;
  adminPermissionLevel: string;
  reference: string;
}

export const DashboardPermission = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [permissionItems, setPermissionItems] = useState<IPermissionItems[] | []>([]);
  const [clientItems, setClientItems] = useState<IClientItems[] | []>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState<IPermissionItems | undefined>(undefined);
  const [permissionRequest, setPermissionRequest] = useState<Omit<IPermissionItems, "reference"> | undefined>(undefined);

  const handleFetchAllPermissions = async () => {
    try {
      const permissionResponse = await NetworkActions.getAllPermission();

      if (permissionResponse) {
        setPermissionItems(permissionResponse);
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET PERMITTED USER");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleSetNewPermission = async () => {
    try {
      if (permissionRequest) {
        const permissionResponse = await NetworkActions.addNewPermission(permissionRequest);
        if (permissionResponse) {
          setOpen(false);
          setPermissionRequest(undefined);
        } else {
          // console.log("failed to add new parameter");
          setError(true);
          setErrorMessage("ERROR_MESSAGE: FAILED TO ADD NEW PERMISSION");
        }
      }
    } catch (error) {
      // console.log(error);
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleDeletePermissionByRef = async () => {
    const deleteRequest = {
      docReference: selectedPermission !== undefined ? selectedPermission.reference : "",
    };

    try {
      const deletePermissionResponse = await NetworkActions.deletePermissionByRef(deleteRequest);

      if (deletePermissionResponse) {
        handleFetchAllPermissions();
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO DELETE CLIENT");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleUpdatePermission = async () => {
    const updatePermissionRequest = {
      ...permissionRequest,
      docReference: selectedPermission?.reference,
    };

    try {
      const permissionResponse = await NetworkActions.updatePermissionByRef(updatePermissionRequest);

      if (permissionResponse) {
        handleFetchAllPermissions();
      } else {
        // console.log("failed to add new parameter");
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO UPDATE PERMISSION");
      }
    } catch (error) {
      // console.log(error);
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleFetchAllClients = async () => {
    try {
      const clientResponse = await NetworkActions.getAllClients();

      if (clientResponse) {
        const sortedClients = clientResponse.sort((a: any, b: any) => (a.clientName.toLowerCase() > b.clientName.toLowerCase() ? 1 : -1));

        setClientItems(sortedClients);
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET ALL CLIENT DATA");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleAddPermission = async () => {
    await handleFetchAllClients();
    await setOpen(true);
  };

  const handleSuccessModal = async () => {
    if (permissionRequest?.clientEmail !== "" && permissionRequest?.clientId !== "") {
      setFetching(true);
      await handleSetNewPermission();
      await handleFetchAllPermissions();
      setFetching(false);
    } else {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: PLEASE SELECT A CLIENT");
    }
  };

  const handleSuccessDeletePermission = async () => {
    setFetching(true);
    await handleDeletePermissionByRef();
    setDeleteOpen(false);
    setFetching(false);
  };

  const handleSuccessEditModal = async () => {
    setFetching(true);
    await handleUpdatePermission();
    setEditOpen(false);
    setFetching(false);
  };

  const content = (
    <div>
      <Box>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 + 12 }} open={fetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </div>
  );

  useEffect(() => {
    const getPermissionData = async () => {
      setFetching(true);
      await handleFetchAllPermissions();
      setFetching(false);
    };

    getPermissionData();
  }, []);

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <ContentContainer>
      <div
        style={{
          flex: 1,
          backgroundColor: "white",
          borderRadius: 12,
          // boxShadow: "40 40",
          borderWidth: 1,
          borderColor: "#31304D",
          borderStyle: "solid",
          height: window.innerHeight - 24,
          marginTop: 12,
          // overflowY: "scroll",
        }}>
        <Box flex={1} justifyContent={"flex-end"} flexDirection={"row"}>
          <Box height={4} width={4} />
          <Box marginRight={w8}>
            <Spacer space={h4} />
            <Button variant="outlined" style={{ color: "#31304D", borderColor: "#31304D", height: 34 }} onClick={handleAddPermission}>
              Add new permission +
            </Button>
            <Spacer space={h4} />
          </Box>
        </Box>

        <div
          style={{
            backgroundColor: "#31304D",
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            height: h48,
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}>
          <ListingTitlePermission />
        </div>
        <Spacer space={h4} />
        <div style={{ overflowY: "scroll", height: window.innerHeight - h48 - h12 - 24 - 34 }}>
          <ListingPermission
            permissions={permissionItems}
            setEditOpen={setEditOpen}
            setDeleteOpen={setDeleteOpen}
            setSelectedPermission={setSelectedPermission}
          />
        </div>
      </div>

      {/* alert show error message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {error ? <CustomAlert alertMessage={errorMessage} /> : null}
      </Box>

      {/* modal to add new permission */}
      <Modal isOpen={open} onClose={() => setOpen(false)} safeAreaTop={true}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Add New Permission</Modal.Header>
          <Modal.Body>
            <AddNewPermission setPermissionRequest={setPermissionRequest} existingClients={clientItems} />
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessModal}>
                Confirm
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>

      {/* modal to add update permission */}
      <Modal isOpen={editOpen} onClose={() => setEditOpen(false)} safeAreaTop={true}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Update Permission Detail</Modal.Header>
          <Modal.Body>
            <UpdatePermission setPermissionRequest={setPermissionRequest} selectedPermission={selectedPermission} />
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessEditModal}>
                Confirm
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>

      {/* modal to delete client */}
      <Modal isOpen={deleteOpen} onClose={() => setDeleteOpen(false)} safeAreaTop={true} height={window.innerHeight}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Delete the permission ?</Modal.Header>
          <Modal.Body>
            <Text>Are you sure you want to delete this permission ?</Text>
            <Text fontWeight={"bold"}>
              {selectedPermission?.isClient ? "Client" : "Admin"} user of {selectedPermission?.clientEmail}
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDeleteOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessDeletePermission}>
                Confirm Delete
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>
    </ContentContainer>
  );
};
