import { GET_ALL_CLIENTS } from "../constants";
import { getLocalStorage } from "../utils";

export const getAllClients = async (): Promise<any> => {
  try {
    const clientResponse = await fetch(GET_ALL_CLIENTS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
    });
    const clientContent = await clientResponse.json();

    if (clientContent.data.message === "Success") {
      const clientsData = clientContent.data.result.data as unknown as IClientItems[];

      return clientsData;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
