const envUrl = {
  localhost: "http://localhost:8001/",
  dev: "https://js-mvp-be-petrolab.vercel.app/",
  prod: "https://js-mvp-be-petrolab-ten.vercel.app/",
};

export const CURRENT_URL = envUrl.prod;

export const AUTH_VERIFY_EMAIL = `${CURRENT_URL}auth/verify-email`;
export const AUTH_VERIFY_ADMIN_EMAIL = `${CURRENT_URL}auth/verify-admin-permission`;
export const AUTH_LOGIN = `${CURRENT_URL}auth/sign-in`;
export const AUTH_SIGNUP = `${CURRENT_URL}auth/sign-up`;

export const VERIFY_ADMIN_PERMISSION = `${CURRENT_URL}auth/verify-admin-permission`;
export const GET_CLIENT_PROFILE_BY_EMAIL = `${CURRENT_URL}client/get-client-by-email`;
export const GET_ALL_SAMPLE_OF_CLIENT = `${CURRENT_URL}sample/get-all-sample-of-client`;

export const GET_ALL_PARAMETERS = `${CURRENT_URL}parameter/get-all-parameters`;
export const ADD_NEW_PARAMETERS = `${CURRENT_URL}parameter/add-parameter`;

export const ADD_NEW_PERMISSION = `${CURRENT_URL}permission/add-permission`;
export const GET_ALL__PERMISSION = `${CURRENT_URL}permission/get-all-permission`;
export const DELETE_PERMISSION_BY_REF = `${CURRENT_URL}permission/delete-permission-by-ref`;
export const GET_PERMISSION_BY_EMAIL = `${CURRENT_URL}permission/get-permission-by-email`;
export const UPDATE_PERMISSION_BY_REF = `${CURRENT_URL}permission/update-permission-by-ref`;

export const ADD_NEW_CLIENTS = `${CURRENT_URL}client/add-client`;
export const GET_ALL_CLIENTS = `${CURRENT_URL}client/get-all-clients`;
export const DELETE_CLIENT_BY_REF = `${CURRENT_URL}client/delete-client-by-ref`;
export const UPDATE_CLIENT_BY_REF = `${CURRENT_URL}client/update-client-by-ref`;

export const ADD_NEW_ONE_SAMPLE = `${CURRENT_URL}sample/add-sample`;

export const GET_ALL_SAMPLE_FOR_ADMIN = `${CURRENT_URL}sample/get-all-sample`;
export const GET_SAMPLE_BY_REF = `${CURRENT_URL}sample/get-sample-by-ref`;
export const UPDATE_SAMPLE_STATUS_BY_REF = `${CURRENT_URL}sample/update-sample-status-by-ref`;
export const UPDATE_SAMPLE_REPORT_BY_REF = `${CURRENT_URL}sample/update-sample-report-by-ref`;
export const UPDATE_SAMPLE_DATA_BY_REF = `${CURRENT_URL}sample/update-sample-data-by-ref`;
export const DELETE_SAMPLE_BY_REF = `${CURRENT_URL}sample/delete-sample-by-ref`;

export const GET_ALL_SAMPLE_IN_PLANT = `${CURRENT_URL}sample/get-all-sample-in-plant`;
export const GET_ALL_SAMPLE_OF_AN_INDEX = `${CURRENT_URL}sample/get-all-sample-of-an-index-sample`;
export const GET_ALL_INDEX_SAMPLE_BY_CLIENT_ID = `${CURRENT_URL}sample/get-all-index-sample-by-client-id`;
export const GET_ALL_SAMPLES_IN_PLANT = `${CURRENT_URL}sample/get-all-sample-in-plant`;
