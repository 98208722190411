import { AUTH_SIGNUP } from "../constants";

export interface AuthLoginRequest {
  email: string;
  password: string;
}

export const authSignUp = async (authRequest: AuthLoginRequest): Promise<any> => {
  try {
    const authSignUpResponse = await fetch(AUTH_SIGNUP, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(authRequest),
    });
    const authSignUpContent = await authSignUpResponse.json();

    return authSignUpContent.data.message;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
