import { GET_SAMPLE_BY_REF } from "../constants";
import { getLocalStorage } from "../utils";

export interface GetSampleByRefRequest {
  docReference: string;
}

export const getSampleByRef = async (request: GetSampleByRefRequest): Promise<any> => {
  try {
    const sampleByRefResponse = await fetch(GET_SAMPLE_BY_REF, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
      body: JSON.stringify(request),
    });
    const sampleByRefContent = await sampleByRefResponse.json();

    if (sampleByRefContent.data.message === "Success") {
      return sampleByRefContent.data.result.data;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
