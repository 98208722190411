import { GET_ALL_SAMPLE_OF_AN_INDEX } from "../constants";
import { getLocalStorage } from "../utils";

export interface getAllSamplesOfAnIndexRequest {
  sampleId: string;
}

export const getAllSamplesOfAnIndex = async (request: getAllSamplesOfAnIndexRequest): Promise<any> => {
  try {
    // get all samples associated with the index sample
    const samplesResponse = await fetch(GET_ALL_SAMPLE_OF_AN_INDEX, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
      body: JSON.stringify(request),
    });
    const samplesContent = await samplesResponse.json();

    if (samplesContent.data.message === "Success") {
      return samplesContent.data.result.data.allSamplesOfIndex;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
