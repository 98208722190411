import { Backdrop, Button, ButtonGroup, CircularProgress } from "@mui/material";
import { Box, Heading, HStack } from "native-base";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CustomAlert, Spacer } from "../../components";
import { GlobalContext, ISample } from "../../context";
import { h16, h24, h8, w12, w24, w8 } from "../../styles";
import { UpdateClientDetails } from "./UpdateClientDetails";
import { NetworkActions } from "../../network-actions";

export interface INewSample {
  sampleData: Omit<ISample, "reference">;
}

export type AddSampleProcess = "client" | "parameter" | "preview";

const initialNewSamples: INewSample = {
  sampleData: {
    clientEmail: "",
    batchNumber: "",
    clientId: "",
    clientName: "",
    sampleLocation: "",
    touchPoint: "",
    clientRequestor: "",
    sampleId: "",
    sampleType: "",
    sampleName: "",
    samplingDate: "",
    receivedDate: "",
    reportRef: [],
    isCompleted: false,
    isPaid: false,
  },
};

const reducer = (state: INewSample, action: { type: string; item: any }) => {
  switch (action.type) {
    case "add_item":
      return {
        ...state,
        sampleData: { ...state.sampleData, ...action.item },
      };
    case "reset":
      return { ...initialNewSamples };
    default:
      return state;
  }
};

export const UpdateOneSample = () => {
  const navigate = useNavigate();
  const { globalState, handleUpdateIsUpdateAction } = useContext(GlobalContext);
  const [state, dispatch] = useReducer(reducer, initialNewSamples);
  const [formErrorMessage] = useState<string>("");
  const [fetching, setFetching] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleGetSampleByRef = async () => {
    const request = {
      docReference: globalState.selectedSampleRef,
    };

    try {
      const sampleByRefResponse = await NetworkActions.getSampleByRef(request);

      if (sampleByRefResponse) {
        const samplesOfClient = sampleByRefResponse;
        dispatch({ type: "add_item", item: { ...samplesOfClient } });
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET SPECIFIC SAMPLE DATA FOR ADMIN");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleUpdateSampleStatusByRef = async () => {
    const request = {
      docReference: globalState.selectedSampleRef,
      ...state.sampleData,
    };

    try {
      const sampleStatusResponse = await NetworkActions.updateSampleStatusByRef(request);

      if (sampleStatusResponse) {
        // go back to ongoing tab
        navigate("/dashboard");
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO UPDATE SAMPLE STATUS");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleNext = async () => {
    setFetching(true);
    // run API to add new sample
    await handleUpdateSampleStatusByRef();
    handleUpdateIsUpdateAction({ ...globalState.isUpdateAction, updateSample: true });
    setFetching(false);
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  const handleBack = () => {
    handleCancel();
  };

  const pageProps = {
    state,
    dispatch,
    formErrorMessage,
  };

  useEffect(() => {
    handleGetSampleByRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <Box paddingX={w24} paddingY={h16}>
      <Box>
        <Heading size="xl" ml="-1">
          Update Sample Detail
        </Heading>
        <Spacer space={h24} />
      </Box>
      <UpdateClientDetails {...pageProps} />
      <HStack space={w12} marginTop={h24}>
        <ButtonGroup variant="outlined" aria-label="outlined button group" style={{ flex: 1 }}>
          <Button aria-label="button" color={"warning"} style={{ cursor: "pointer", flex: 1 }} onClick={handleBack}>
            Cancel Update
          </Button>
          <Button aria-label="button" style={{ cursor: "pointer", flex: 1 }} onClick={handleNext}>
            Confirm Update
          </Button>
        </ButtonGroup>
      </HStack>

      {/* alert show error message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {error ? <CustomAlert alertMessage={errorMessage} /> : null}
      </Box>

      {/* loading network modal */}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 5 }} open={fetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
