import CryptoJS from "crypto-js";
import { Box, Center, Input, Text } from "native-base";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CustomAlert, GeneralButton, Loading, LoginContainer } from "../../components";
import { ERROR_MESSAGE, LOGIN } from "../../constants";
import { GlobalContext, ISampleAmount } from "../../context";
import { h24, h4, h8, w16, w4, w8 } from "../../styles";
import { setLocalStorage } from "../../utils";
import { NetworkActions } from "../../network-actions";

export const Login = () => {
  const navigate = useNavigate();
  const { handleInitAllSamplesAdmin } = useContext(GlobalContext);

  const [fetching, setFetching] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const adminLevelRef = useRef<string>("1");

  const handleAuthLogin = async (email: string) => {
    const authAdminRequest = {
      adminEmail: email,
    };

    try {
      const authPermissionResponse = await NetworkActions.verifyAdminPermission(authAdminRequest);

      if (authPermissionResponse) {
        adminLevelRef.current = authPermissionResponse;
        setLocalStorage("admin-level", authPermissionResponse);
        return true;
      } else {
        setError(true);
        setErrorMessage("Permission denied. You don't have access to the system.");
      }
    } catch (error) {
      setError(true);
    }
  };

  const handleGetAllSampleOfAllClient = async (value: number) => {
    const paginationRequest = {
      page: value,
    };
    try {
      const samplesResponse = await NetworkActions.getAllSampleForAdmin(paginationRequest);

      if (samplesResponse) {
        return samplesResponse;
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET ALL SAMPLES DATA FOR ADMIN");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleGetAdminData = async (email: string) => {
    const initialPage = 1;
    const allClientSamples = await handleGetAllSampleOfAllClient(initialPage);
    setLocalStorage("total-page-dashboard", `${allClientSamples.totalPage}`);
    const samplesAmount: ISampleAmount = {
      currentPage: initialPage,
      ongoingDocs: allClientSamples.ongoingDocs,
      completedDocs: allClientSamples.completedDocs,
    };
    await handleInitAllSamplesAdmin(email, allClientSamples.sampleItems, samplesAmount, adminLevelRef.current);
  };

  const loginHelper = async () => {
    const userVerified = await handleAuthLogin(email);
    if (typeof userVerified === "boolean" && userVerified === true) {
      const authRequest = {
        email: email,
        password: CryptoJS.AES.encrypt(password, "P@E!$%TROLAB+").toString(),
      };
      try {
        const authSignInResponse = await NetworkActions.authLogin(authRequest);
        if (authSignInResponse) {
          // already saved token in network action function
          // fetch data api
          await handleGetAdminData(email);
          // go to dashboard
          navigate("/Dashboard");
        } else {
          setError(true);
          setErrorMessage("ERROR_MESSAGE: FAILED TO LOGIN");
        }
      } catch (error) {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
      }
      return true;
    } else {
      return false;
    }
  };

  const handleResetCredential = () => {
    setEmail("");
    setPassword("");
  };

  const handleCheckGoogleAccount = async () => {
    setFetching(true);

    if (email !== "" && password !== "") {
      await loginHelper();
    } else {
      setError(true);
      setErrorMessage(ERROR_MESSAGE.INPUT_CREDENTIAL);
    }

    setFetching(false);
    handleResetCredential();
  };

  const handleGoToSignUp = () => {
    navigate("/signup");
  };

  const handleUpdateShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <Center>
      <LoginContainer title={"PetroLab\nAdmin"} subtitle={"V 1.0"} subLabel={""}>
        <Box>
          <Box marginBottom={h8}>
            <Input
              autoCapitalize={"none"}
              onChangeText={setEmail}
              placeholder={LOGIN.PLACEHOLDER_EMAIL}
              value={email}
              variant="filled"
              style={{
                padding: w16,
                borderRadius: 4,
                width: "auto",
              }}
            />

            <Box height={h4} />

            <Input
              maxLength={8}
              onChangeText={setPassword}
              placeholder={LOGIN.PLACEHOLDER_PASSWORD}
              // secureTextEntry={true}
              value={password}
              variant="filled"
              type={showPassword ? "text" : "password"}
              InputRightElement={
                <div onClick={handleUpdateShowPassword} style={{ cursor: "pointer", paddingRight: w4 }}>
                  <Text>{showPassword ? "hide" : "show"}</Text>
                </div>
              }
              style={{
                padding: w16,
                borderRadius: 4,
                width: "auto",
              }}
            />
          </Box>

          <GeneralButton
            label={LOGIN.BUTTON_LOGIN}
            showIcon={true}
            iconSize={h24}
            filled={false}
            disabled={fetching}
            onPress={handleCheckGoogleAccount}
            signUp={false}
            commentText={LOGIN.LABEL_SIGNUP}
            commentOnPress={handleGoToSignUp}
          />
        </Box>
      </LoginContainer>

      {/* alert show error message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {error ? <CustomAlert alertMessage={errorMessage} /> : null}
      </Box>

      {/* view show loading */}
      {fetching ? (
        <Box position={"absolute"} backgroundColor={"gray.300"} width={window.innerWidth} height={window.innerHeight} opacity={0.7}>
          <Loading />
        </Box>
      ) : null}
    </Center>
  );
};
