import { Backdrop, Button, ButtonGroup, CircularProgress } from "@mui/material";
import { Box, Modal, Text } from "native-base";
import React, { useEffect, useRef, useState } from "react";

import { ContentContainer, CustomAlert, ListingTitleClient, Spacer } from "../../components";

import { h12, h4, h48, h8, w8 } from "../../styles";
import { AddNewClient } from "./AddNewClient";
import { ClientListingParameter } from "./ClientListingParameter";
import { UpdateClient } from "./UpdateClient";
import { NetworkActions } from "../../network-actions";

export const DashboardClient = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [clientItems, setClientItems] = useState<IClientItems[] | []>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formErrorMessage, setFormErrorMessage] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<IClientItems | undefined>(undefined);
  const [clientRequest, setClientRequest] = useState<Omit<IClientItems, "reference"> | undefined>(undefined);
  const networkActionRef = useRef<boolean>(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleFetchAllClients = async () => {
    try {
      const clientResponse: IClientItems[] = await NetworkActions.getAllClients();

      if (clientResponse) {
        const sortedClients = clientResponse.sort((a: any, b: any) => (a.clientName.toLowerCase() > b.clientName.toLowerCase() ? 1 : -1));
        setClientItems(sortedClients);

        // there's no clientId returned from BE
        // const sortedClients = clientsData.sort((a: any, b: any) => (a.clientId.toLowerCase() > b.clientId.toLowerCase() ? 1 : -1));
        // const valuedClients = sortedClients.filter(
        //   (eachClient: IClientItems) => eachClient.clientId !== "-" && eachClient.clientEmail !== "-",
        // );
        // const noValueClients = sortedClients.filter(
        //   (eachClient: IClientItems) => eachClient.clientId === "-" && eachClient.clientEmail === "-",
        // );
        // // const sortedClients = clientsData.sort((a: any, b: any) => (a.clientName.toLowerCase() > b.clientName.toLowerCase() ? 1 : -1));

        // setClientItems(valuedClients.concat(noValueClients));
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET ALL CLIENT DATA");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleSetNewClient = async () => {
    try {
      if (clientRequest) {
        const clientResponse = await NetworkActions.addNewClient(clientRequest);

        if (clientResponse) {
          setOpen(false);
          setClientRequest(undefined);
        } else {
          setError(true);
          setErrorMessage("ERROR_MESSAGE: FAILED TO ADD NEW CLIENT");
        }
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleDeleteClientByRef = async () => {
    const deleteRequest = {
      docReference: selectedClient !== undefined ? selectedClient.reference : "",
    };

    try {
      const deleteClientResponse = await NetworkActions.deleteClientByRef(deleteRequest);

      if (deleteClientResponse) {
        handleFetchAllClients();
        setDeleteOpen(false);
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO DELETE CLIENT");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleGetPermissionByEmail = async () => {
    const getPermissionRequest = {
      clientEmail: selectedClient !== undefined ? selectedClient.clientEmail : "",
    };

    try {
      const getPermissionResponse = await NetworkActions.getPermissionByEmail(getPermissionRequest);

      if (getPermissionResponse) {
        return getPermissionResponse;
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO DELETE CLIENT");
        return undefined;
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleDeletePermissionByRef = async (reference: string) => {
    const deleteRequest = {
      docReference: reference,
    };

    try {
      const deletePermissionResponse = await NetworkActions.deletePermissionByRef(deleteRequest);

      if (deletePermissionResponse) {
        await handleDeleteClientByRef();
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO DELETE CLIENT");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleUpdateClient = async () => {
    const updateClientRequest = {
      ...clientRequest,
      docReference: selectedClient?.reference,
    };

    try {
      const updateClientResponse = await NetworkActions.updateClientByRef(updateClientRequest);

      if (updateClientResponse) {
        handleFetchAllClients();
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO UPDATE CLIENT");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  // TODO reset of clientRequest value is not working properly
  const handleResetClientRequest = async () => {
    setClientRequest({
      clientEmail: "",
      clientId: "",
      clientName: "",
      clientAddress: "",
    });
  };

  const handleSuccessModal = async () => {
    setFetching(true);
    if (clientRequest !== undefined) {
      if (clientRequest.clientEmail !== "" && clientRequest.clientName !== "" && clientRequest.clientId !== "") {
        networkActionRef.current = true;
        await handleSetNewClient();
        await handleFetchAllClients();
        await handleResetClientRequest();
        setFormErrorMessage("");
        networkActionRef.current = false;
      } else if (clientRequest.clientEmail === "") {
        setFormErrorMessage("Client email is required");
      } else if (clientRequest.clientId === "") {
        setFormErrorMessage("Client id is required");
      } else {
        setFormErrorMessage("Client name is required");
      }
    }
    setFetching(false);
  };

  const handleSuccessEditModal = async () => {
    setFetching(true);
    if (clientRequest !== undefined) {
      if (clientRequest.clientEmail !== "" && clientRequest.clientName !== "") {
        await handleUpdateClient();
        setEditOpen(false);
        await handleResetClientRequest();
      } else if (clientRequest.clientEmail === "") {
        setFormErrorMessage("Client email is required");
      } else {
        setFormErrorMessage("Client name is required");
      }
    }
    setFetching(false);
  };

  const handleSuccessDeleteClient = async () => {
    setFetching(true);
    const permissionResponse = await handleGetPermissionByEmail();
    if (permissionResponse !== undefined && Object.keys(permissionResponse).length !== 0) {
      // delete permission if there's permission data correspondece to this client
      await handleDeletePermissionByRef(permissionResponse.reference);
    } else {
      await handleDeleteClientByRef();
    }
    setFetching(false);
  };

  const content = (
    <div>
      <Box>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 + 12 }} open={fetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </div>
  );

  useEffect(() => {
    const handleGetClients = async () => {
      setFetching(true);
      await handleFetchAllClients();
      setFetching(false);
    };

    handleGetClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <ContentContainer>
      <div
        style={{
          flex: 1,
          backgroundColor: "white",
          borderRadius: 12,
          borderWidth: 1,
          borderColor: "#31304D",
          borderStyle: "solid",
          height: window.innerHeight - 24,
          marginTop: 12,
        }}>
        <Box flex={1} justifyContent={"flex-end"} flexDirection={"row"}>
          <Box height={4} width={4} />
          <Box marginRight={w8}>
            <Spacer space={h4} />
            <Button variant="outlined" style={{ color: "#31304D", borderColor: "#31304D", height: 34 }} onClick={handleOpenModal}>
              Add New Client +
            </Button>
            <Spacer space={h4} />
          </Box>
        </Box>

        <div
          style={{
            backgroundColor: "#31304D",
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            height: h48,
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}>
          <ListingTitleClient />
        </div>
        <Spacer space={h4} />
        <div style={{ overflowY: "scroll", height: window.innerHeight - h48 - h12 - 24 - 34 }}>
          <ClientListingParameter
            clients={clientItems}
            setDeleteOpen={setDeleteOpen}
            setEditOpen={setEditOpen}
            setSelectedClient={setSelectedClient}
          />
        </div>
      </div>

      {/* alert show error message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {error ? <CustomAlert alertMessage={errorMessage} /> : null}
      </Box>

      {/* modal to add new client */}
      <Modal
        isOpen={open}
        onClose={() => {
          handleResetClientRequest();
          setOpen(false);
        }}
        safeAreaTop={true}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Add New Client</Modal.Header>
          <Modal.Body>
            <AddNewClient
              setClientRequest={setClientRequest}
              formErrorMessage={formErrorMessage}
              setFormErrorMessage={setFormErrorMessage}
              networkActionRef={networkActionRef.current}
              existingClients={clientItems}
            />
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessModal}>
                Confirm
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>

      {/* modal to update client */}
      <Modal
        isOpen={editOpen}
        onClose={() => {
          setEditOpen(false);
          handleResetClientRequest();
        }}
        safeAreaTop={true}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>
            Update Client {`(${selectedClient !== undefined && selectedClient.clientId !== undefined ? selectedClient.clientId : "-"})`}
          </Modal.Header>
          <Modal.Body>
            <UpdateClient
              setClientRequest={setClientRequest}
              formErrorMessage={formErrorMessage}
              setFormErrorMessage={setFormErrorMessage}
              selectedClient={selectedClient}
            />
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessEditModal}>
                Confirm
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>

      {/* modal to delete client */}
      <Modal isOpen={deleteOpen} onClose={() => setDeleteOpen(false)} safeAreaTop={true} height={window.innerHeight}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Delete the client ?</Modal.Header>
          <Modal.Body>
            <Text>Are you sure you want to delete this client ?</Text>
            <Text fontWeight={"bold"}>{selectedClient !== undefined ? selectedClient.clientName : "-"}</Text>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDeleteOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessDeleteClient}>
                Confirm Delete
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>
    </ContentContainer>
  );
};
