import { UPDATE_SAMPLE_STATUS_BY_REF } from "../constants";
import { getLocalStorage } from "../utils";

export interface UpdateSampleStatusByRefRequest {
  docReference: string;
  isCompleted: boolean;
}

export const updateSampleStatusByRef = async (request: UpdateSampleStatusByRefRequest): Promise<any> => {
  try {
    const sampleStatusResponse = await fetch(UPDATE_SAMPLE_STATUS_BY_REF, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
      body: JSON.stringify(request),
    });
    const sampleStatusContent = await sampleStatusResponse.json();

    return sampleStatusContent.data.message === "Success";
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }

  return false;
};
