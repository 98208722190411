import { ADD_NEW_PERMISSION } from "../constants";
import { IPermissionItems } from "../pages/permission/DashboardPermission";
import { getLocalStorage } from "../utils";

export interface AddNewPermissionRequest extends Omit<IPermissionItems, "reference"> {}

export const addNewPermission = async (permissionRequest: AddNewPermissionRequest): Promise<any> => {
  try {
    const permissionResponse = await fetch(ADD_NEW_PERMISSION, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
      body: JSON.stringify(permissionRequest),
    });
    const permissionContent = await permissionResponse.json();

    return permissionContent.data.message === "Success";
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
