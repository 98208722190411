import { GET_ALL__PERMISSION } from "../constants";
import { getLocalStorage } from "../utils";

export const getAllPermission = async (): Promise<any> => {
  try {
    const permissionResponse = await fetch(GET_ALL__PERMISSION, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalStorage("id-token")}`,
      },
    });
    const permissionContent = await permissionResponse.json();

    if (permissionContent.data.message === "Success") {
      const permissions = permissionContent.data.result.permissionData;
      const sortedPermission = permissions.sort((a: any, b: any) => (a.clientEmail > b.clientEmail ? 1 : -1));
      return sortedPermission;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
