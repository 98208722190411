import React, { createContext, FunctionComponent, ReactNode, useState } from "react";

import { h128 } from "../styles";

interface IStyleState {
  headerHeight: number;
}

export interface IStyleContext {
  styleState: IStyleState;
  handleUpdateStyle: (value: Partial<IStyleState>) => void;
}

interface IStyleProvider {
  children: ReactNode;
}

const initialState: IStyleState = {
  headerHeight: h128,
};

export const StyleContext = createContext<IStyleContext>({
  styleState: initialState,
  handleUpdateStyle: () => {},
});

const { Provider } = StyleContext;

export const StyleProvider: FunctionComponent<IStyleProvider> = ({ children }: IStyleProvider) => {
  const [styleState, setStyleState] = useState<IStyleState>(initialState);

  const handleUpdateStyle = (value: Partial<IStyleState>) => {
    setStyleState({ ...styleState, ...value });
  };

  return (
    <Provider
      value={{
        styleState,
        handleUpdateStyle,
      }}>
      {children}
    </Provider>
  );
};
