import "./App.css";

import { NativeBaseProvider } from "native-base";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { GlobalProvider, StyleProvider } from "./context";
import { AddMultipleSample } from "./pages/addMultipleSample/AddMultipleSample";
import { AddOneSample } from "./pages/addOneSample/AddOneSample";
import { Dashboard } from "./pages/Dashboard";
import { Login, SignUp } from "./pages/Login";
import { UpdateOneSample } from "./pages/updateOneSample/UpdateOneSample";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <StyleProvider>
            <NativeBaseProvider>
              <div style={{ height: window.innerHeight }}>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/add-one-sample" element={<AddOneSample />} />
                    <Route path="/add-multiple-sample" element={<AddMultipleSample />} />
                    <Route path="/update-sample" element={<UpdateOneSample />} />
                    <Route path="dashboard" element={<Dashboard />} />
                  </Routes>
                </BrowserRouter>
              </div>
            </NativeBaseProvider>
          </StyleProvider>
        </GlobalProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
