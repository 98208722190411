import { VERIFY_ADMIN_PERMISSION } from "../constants";
import { setLocalStorage } from "../utils";

export interface VerifyAdminPermissionRequest {
  adminEmail: string;
}

export const verifyAdminPermission = async (authAdminRequest: VerifyAdminPermissionRequest): Promise<any> => {
  try {
    const authPermissionResponse = await fetch(VERIFY_ADMIN_PERMISSION, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(authAdminRequest),
    });
    const authAdminPermissionContent = await authPermissionResponse.json();

    if (authAdminPermissionContent.data.message === "Success" && authAdminPermissionContent.data.result.data.isPermitted === true) {
      setLocalStorage("admin-level", authAdminPermissionContent.data.result.data.adminPermissionLevel);
      return authAdminPermissionContent.data.result.data.adminPermissionLevel;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
