import { Backdrop, CircularProgress } from "@mui/material";
import { Box, Text, View } from "native-base";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";

import { CustomAlert, GlobalContainer } from "../components";
import { GlobalContext, ISample, ISampleAmount, ISearchDashboard } from "../context";
import { useKeyDown } from "../hooks";
import { h8, w8 } from "../styles";
import { setLocalStorage } from "../utils";
import { DashboardClient } from "./client/DashboardClient";
import { CompletedListing } from "./dashboard/CompletedListing";
import { OngoingListing } from "./dashboard/OngoingListing";
import { PlantOverview } from "./dashboard/PlantOverview";
import { DashboardParameter } from "./parameter/DashboardParameter";
import { DashboardPermission } from "./permission/DashboardPermission";
import { SampleDetail } from "./sampleDetail/SampleDetail";
import { NetworkActions } from "../network-actions";

export interface ITabRoutes {
  key: string;
  title: string;
}

export const Dashboard = () => {
  const { globalState, handleInitAllSamplesAdmin, handleUpdateIsUpdateAction } = useContext(GlobalContext);
  const { selectedMenu: contextSelectedMenu, adminEmail, searchDashboard } = globalState;
  const { searchText } = searchDashboard;
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedSampleRef, setSelectedSampleRef] = useState<string | undefined>(undefined);
  const [fetching, setFetching] = useState<boolean>(false);
  const [localOngoingSamples, setlocalOngoingSamples] = useState<ISample[]>([]);
  const [localCompletedSamples, setlocalCompletedSamples] = useState<ISample[]>([]);
  const pageRef = useRef<number>(1);

  const [routes] = useState<ITabRoutes[]>([
    {
      key: "Ongoing",
      title: "Ongoing",
    },
    {
      key: "Completed",
      title: "Completed",
    },
  ]);

  const [activeTab, setActiveTab] = useState<string>("Ongoing");
  const [showDetail, setShowDetail] = useState<boolean>(false);

  const handleCloseDetailPage = () => {
    setShowDetail(false);
  };

  const handleShowSampleDetail = (value: string | undefined) => {
    setSelectedSampleRef(value);
    setShowDetail(!showDetail);
  };

  const handleUpdateTab = (value: string) => {
    setActiveTab(value);
  };

  const handleGetAllSampleOfAllClient = async (value: number, searchDashboard?: ISearchDashboard) => {
    const paginationRequest = {
      page: value,
      tab: activeTab,
      searchText: searchDashboard !== undefined ? searchDashboard.searchText.toLowerCase() : "",
      byCategory: searchDashboard !== undefined ? searchDashboard.byCategory : "",
    };
    try {
      const samplesResponse = await NetworkActions.getAllSampleForAdmin(paginationRequest);

      if (samplesResponse) {
        return samplesResponse;
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET ALL SAMPLES DATA FOR ADMIN");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const globalContainerProps = {
    handleCloseDetailPage: handleCloseDetailPage,
  };

  const contentProps = {
    handleShowSampleDetail: handleShowSampleDetail,
    localOngoingSamples: localOngoingSamples,
    localCompletedSamples: localCompletedSamples,
  };

  const sampleDetailsProps = {
    handleShowSampleDetail: handleShowSampleDetail,
    selectedSampleRef: selectedSampleRef,
    setError: setError,
    error: error,
    setErrorMessage: setErrorMessage,
  };

  const handleUpdatePagination = async (page: number) => {
    setFetching(true);
    pageRef.current = page;
    const isSearchDashboard = searchDashboard?.searchText !== "" ? searchDashboard : undefined;
    // original
    const updateSamples = await handleGetAllSampleOfAllClient(page, isSearchDashboard);
    setLocalStorage("total-page-dashboard", `${updateSamples.totalPage}`);
    const samplesAmount: ISampleAmount = {
      currentPage: page,
      ongoingDocs: updateSamples.ongoingDocs,
      completedDocs: updateSamples.completedDocs,
    };
    await handleInitAllSamplesAdmin(adminEmail, updateSamples.sampleItems, samplesAmount, undefined);
    if (activeTab === "Ongoing") {
      setlocalOngoingSamples(updateSamples.sampleItems);
    } else {
      setlocalCompletedSamples(updateSamples.sampleItems);
    }
    setFetching(false);
  };

  const handleUpdateSearchDashboard = async (searchDashboard: ISearchDashboard) => {
    setFetching(true);
    pageRef.current = 1;
    // original
    const updateSamples = await handleGetAllSampleOfAllClient(1, searchDashboard);
    setLocalStorage("total-page-dashboard", `${updateSamples.totalPage}`);
    const samplesAmount: ISampleAmount = {
      currentPage: 1,
      ongoingDocs: updateSamples.ongoingDocs,
      completedDocs: updateSamples.completedDocs,
    };
    const updatedSearchDashboard = searchDashboard.searchText === "" ? searchDashboard : undefined;
    await handleInitAllSamplesAdmin(adminEmail, updateSamples.sampleItems, samplesAmount, undefined, updatedSearchDashboard);
    if (activeTab === "Ongoing") {
      setlocalOngoingSamples(updateSamples.sampleItems);
    } else {
      setlocalCompletedSamples(updateSamples.sampleItems);
    }
    setFetching(false);
  };

  let tabContent: JSX.Element = <View />;
  switch (activeTab) {
    case "Ongoing":
      tabContent = <OngoingListing {...contentProps} />;
      break;
    case "Completed":
      tabContent = <CompletedListing {...contentProps} />;
      break;
    default:
      tabContent = (
        <View>
          <Text>No content</Text>
        </View>
      );
      break;
  }

  let menuContent = (
    <Fragment>
      <PlantOverview
        activeTab={activeTab}
        handleUpdateTab={handleUpdateTab}
        handleUpdatePagination={handleUpdatePagination}
        handleUpdateSearchDashboard={handleUpdateSearchDashboard}
        tabRoutes={routes}>
        {tabContent}
      </PlantOverview>
    </Fragment>
  );
  switch (contextSelectedMenu) {
    case "Analysis":
      menuContent = (
        <Fragment>
          <PlantOverview
            activeTab={activeTab}
            handleUpdateTab={handleUpdateTab}
            handleUpdatePagination={handleUpdatePagination}
            handleUpdateSearchDashboard={handleUpdateSearchDashboard}
            tabRoutes={routes}>
            {tabContent}
          </PlantOverview>
        </Fragment>
      );
      break;
    case "Client":
      menuContent = (
        <Fragment>
          <DashboardClient />
        </Fragment>
      );
      break;
    case "Permission":
      menuContent = (
        <Fragment>
          <DashboardPermission />
        </Fragment>
      );
      break;
    case "Parameter":
      menuContent = (
        <Fragment>
          <DashboardParameter />
        </Fragment>
      );
      break;

    default:
      menuContent = (
        <View>
          <Text>No content</Text>
        </View>
      );
      break;
  }

  const fetchData = async (isUpdateTab?: boolean) => {
    const page = isUpdateTab ? 1 : pageRef.current;
    setFetching(true);
    const isSearchDashboard = searchDashboard.searchText !== "" ? searchDashboard : undefined;
    const updateSamples = await handleGetAllSampleOfAllClient(page, isSearchDashboard);
    setLocalStorage("total-page-dashboard", `${updateSamples.totalPage}`);
    const samplesAmount: ISampleAmount = {
      currentPage: page,
      ongoingDocs: updateSamples.ongoingDocs,
      completedDocs: updateSamples.completedDocs,
    };
    await handleInitAllSamplesAdmin(adminEmail, updateSamples.sampleItems, samplesAmount, undefined);
    if (activeTab === "Ongoing") {
      setlocalOngoingSamples(updateSamples.sampleItems);
    } else {
      setlocalCompletedSamples(updateSamples.sampleItems);
    }
    setFetching(false);
  };

  useEffect(() => {
    if (globalState.isUpdateAction.updateSample) {
      fetchData();
      handleUpdateIsUpdateAction({ ...globalState.isUpdateAction, updateSample: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDetail]);

  useEffect(() => {
    fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  useKeyDown(() => {
    if (searchText !== "" && searchDashboard) {
      handleUpdateSearchDashboard(searchDashboard);
    }
  }, ["Enter"]);

  return (
    <GlobalContainer {...globalContainerProps}>
      <Fragment>
        {showDetail ? (
          <div style={{ overflowY: "scroll", height: window.innerHeight, backgroundColor: "white" }}>
            <SampleDetail {...sampleDetailsProps} />
          </div>
        ) : (
          <Fragment>{menuContent}</Fragment>
        )}
        {/* alert show error message  */}
        <Box position={"absolute"} right={w8} top={h8}>
          {error ? <CustomAlert alertMessage={errorMessage} /> : null}
        </Box>

        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 + 12 }} open={fetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fragment>
    </GlobalContainer>
  );
};
