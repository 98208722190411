import { AUTH_LOGIN } from "../constants";
import { setLocalStorage } from "../utils";

export interface AuthLoginRequest {
  email: string;
  password: string;
}

export const authLogin = async (authRequest: AuthLoginRequest): Promise<any> => {
  try {
    const authSignInResponse = await fetch(AUTH_LOGIN, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(authRequest),
    });
    const authSignInContent = await authSignInResponse.json();
    // console.log("authSignInContent", authSignInContent);

    if (authSignInContent.data.message === "Success") {
      // set token to ref
      const receivedToken = authSignInContent.data.result.idToken;
      setLocalStorage("id-token", receivedToken);

      return authRequest.email;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }

  return undefined;
};
